var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main ant-card",style:({top:(_vm.screenHeight/2-230)+'px',right:_vm.layoutRight+'px'})},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('main.title')))]),_c('a-form',{ref:"formLogin",staticClass:"user-layout-login",attrs:{"id":"formLogin","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'account',
          {rules: [{ required: true, type: 'email', message: _vm.$t('user.email.tip') }, { validator: _vm.handleUsernameOrEmail }], validateTrigger: 'change'}
        ]),expression:"[\n          'account',\n          {rules: [{ required: true, type: 'email', message: $t('user.email.tip') }, { validator: handleUsernameOrEmail }], validateTrigger: 'change'}\n        ]"}],attrs:{"size":"large","type":"text","placeholder":_vm.$t('user.account')}},[_c('a-icon',{style:({ color: 'rgba(0,0,0,.25)' }),attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'passwd',
          {rules: [{ required: true, message: _vm.$t('user.password.tip') }], validateTrigger: 'blur'}
        ]),expression:"[\n          'passwd',\n          {rules: [{ required: true, message: $t('user.password.tip') }], validateTrigger: 'blur'}\n        ]"}],attrs:{"size":"large","type":"password","autocomplete":"false","placeholder":_vm.$t('user.password')}},[_c('a-icon',{style:({ color: 'rgba(0,0,0,.25)' }),attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-row',{attrs:{"gutter":6}},[_c('a-col',{attrs:{"xs":14,"md":15,"lg":16}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'captcha',
              {rules: [{ required: true, message: _vm.$t('user.captcha.tip') }]}
            ]),expression:"[\n              'captcha',\n              {rules: [{ required: true, message: $t('user.captcha.tip') }]}\n            ]"}],attrs:{"size":"large","autocomplete":"false","placeholder":_vm.$t('user.captcha')}},[_c('a-icon',{style:({ color: 'rgba(0,0,0,.25)' }),attrs:{"slot":"prefix","type":"safety-certificate"},slot:"prefix"})],1)],1)],1),_c('a-col',{attrs:{"xs":10,"md":9,"lg":8}},[_c('img',{staticStyle:{"height":"36px","margin-left":"16px","position":"relative","top":"2px","cursor":"pointer"},attrs:{"src":_vm.captchaUrl},on:{"click":_vm.refreshCaptcha}})]),_c('a-form-item',{staticStyle:{"margin-top":"24px"}},[_c('a-button',{staticClass:"login-button",attrs:{"size":"large","type":"primary","htmlType":"submit","loading":_vm.state.loginBtn,"disabled":_vm.state.loginBtn}},[_vm._v(_vm._s(_vm.$t('user.login'))+" ")])],1)],1)],1),_c('div',[_c('a-checkbox',{model:{value:(_vm.readed),callback:function ($$v) {_vm.readed=$$v},expression:"readed"}},[_vm._v(_vm._s(_vm.$t('user.readAndAgree'))),_c('a',{attrs:{"href":"https://www.reflexwireless.com/en/terms-and-conditions-japanese/","target":"_blank"}},[_vm._v(_vm._s(_vm.$t('user.UserServiceAgreement')))])])],1),_c('div',{staticClass:"user-login-other"},[_c('div',{staticClass:"g_id_signin float-left",attrs:{"data-locale":"ja","data-type":"standard","data-size":"large","data-theme":"outline","data-text":"sign_in_with","data-shape":"rectangular","data-logo_alignment":"left"}}),_c('router-link',{staticClass:"register",attrs:{"to":{ name: 'register' }}},[_vm._v(_vm._s(_vm.$t('user.regnow')))])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }